import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import { LoginTypes } from '@features/user/ui';
import { useCheckIdentifyAuthType, usePurchaseFromQuery } from '@ui';
import { useCallback } from 'react';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';
import {
  PhoneFormValues,
  useCheckPhoneFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface Params {
  setIsPurchase: (isPurchase: boolean) => void;
  setAuthType: (authType: AuthTypes | null) => void;
  setLoginType: (loginType: LoginTypes | null) => void;
  setCheckingStatus: (isChecked: boolean) => void;
  setLogin: (login: string | null) => void;
}

export const useIdentificationPage = (params: Params) => {
  const {
    setIsPurchase,
    setAuthType,
    setLoginType,
    setCheckingStatus,
    setLogin,
  } = params;

  const { CheckPhoneFormSchema } = useCheckPhoneFormSchema();
  const methods = useForm<PhoneFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(CheckPhoneFormSchema),
    mode: 'onChange',
  });

  const { handleSubmit, reset, getValues } = methods;

  const handleResetFormWithValue = useCallback(() => {
    const phone = getValues('phone');
    reset({ phone });
  }, [getValues, reset]);

  const { askHcaptchaToken, component: captchaComponent } = useHCaptcha(
    SITE_KEY,
    false,
    {
      onClose: handleResetFormWithValue,
      onChalExpired: handleResetFormWithValue,
      onError: handleResetFormWithValue,
    },
  );

  const { handleCheckIdentify, checkIdentify } = useCheckIdentifyAuthType({
    setAuthType,
    setLoginType,
    setCheckingStatus,
    beforeCheckingHandler: setLogin,
    getCaptcha: askHcaptchaToken,
  });

  const onPurchase = useCallback(
    (phone) => {
      checkIdentify(phone);
      setIsPurchase(true);
    },
    [checkIdentify, setIsPurchase],
  );

  usePurchaseFromQuery({ onPurchase });

  const onSubmit = (type: LoginTypes) =>
    handleSubmit((values) => handleCheckIdentify(values, type));

  return {
    onSubmit,
    methods,
    captchaComponent,
  };
};
