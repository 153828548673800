import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import {
  AuthPage,
  RegisterPage,
  IdentificationPage,
  AuthBlock,
  LoginTypes,
} from '@features/user/ui';
import { AuthTemplate, Notifications } from '@ui';
import { useCallback, useState } from 'react';

export const AuthNewPage = () => {
  const [login, setLogin] = useState<string | null>(null);
  const [authType, setAuthType] = useState<AuthTypes | null>(null);
  const [loginType, setLoginType] = useState<LoginTypes | null>(null);
  const [isChecked, setCheckingStatus] = useState<boolean>(false);
  const [isPurchase, setIsPurchase] = useState<boolean>(false);

  const onBackHandler = () => {
    setLoginType(null);
    setCheckingStatus(false);
    setAuthType(null);
  };

  const getAuthComponent = useCallback(() => {
    if (!isChecked || !login || !authType) {
      return null;
    }

    switch (authType) {
      case 'auth':
        if (!loginType) {
          return null;
        }

        return (
          <AuthPage
            type={loginType}
            phone={login}
            onBackHandler={onBackHandler}
          />
        );
      case 'register':
        return (
          <RegisterPage
            phone={login}
            onBackHandler={!isPurchase ? onBackHandler : undefined}
          />
        );
    }
  }, [isChecked, login, authType, loginType, isPurchase]);

  return (
    <AuthTemplate>
      <AuthBlock>
        {!isChecked || !authType ? (
          <IdentificationPage
            setCheckingStatus={setCheckingStatus}
            setIsPurchase={setIsPurchase}
            setAuthType={setAuthType}
            setLogin={setLogin}
            setLoginType={setLoginType}
            login={login}
          />
        ) : (
          getAuthComponent()
        )}
        <Notifications target="authorization" />
      </AuthBlock>
    </AuthTemplate>
  );
};
