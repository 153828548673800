import { formatPhoneWithPlus } from '@teledoctor/common/dist/helpers/phone';
import { useEffect } from 'react';

interface Params {
  onPurchase?: (phone: string) => Promise<void> | void;
}

export const usePurchaseFromQuery = (params: Params) => {
  const { onPurchase } = params;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const phone = searchParams.get('number');
    if (!phone) {
      return;
    }

    searchParams.delete('number');
    const { size } = searchParams;

    const newURL = `${window.location.pathname}${
      size !== 0 ? `?${searchParams}` : ''
    }`;

    window.history.replaceState({}, '', newURL);

    const formattedPhone = phone.startsWith('+')
      ? phone
      : formatPhoneWithPlus(phone);

    onPurchase?.(formattedPhone);
  }, [onPurchase]);
};
