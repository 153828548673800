import { LoginCodeForm } from '@features/user/ui';
import { useAuthByCode } from './use-auth-by-code';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';

export const AuthByCode = ({ phone }: { phone: string }) => {
  const { askHcaptchaToken, component: captchaComponent } =
    useHCaptcha(SITE_KEY);

  const { requestCodeAuthHandler, onSuccessCode } = useAuthByCode({
    phone,
    askHcaptchaToken,
  });

  return (
    <>
      {captchaComponent}
      <LoginCodeForm
        phone={phone}
        requestCodeAgain={requestCodeAuthHandler}
        onSuccessForm={onSuccessCode}
      />
    </>
  );
};
