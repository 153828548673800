import { PhoneCheckSimple } from '@features/user/ui';
import { Params, useIdentificationPage } from './use-identificaton-page';

interface Props extends Params {
  login: string | null;
}
export const IdentificationPage = (props: Props) => {
  const { login, ...otherProps } = props;

  const { methods, onSubmit, captchaComponent } =
    useIdentificationPage(otherProps);

  const {
    setValue,
    formState: { isSubmitting, isValid },
  } = methods;

  return (
    <>
      {captchaComponent}
      <h1 className="h1">Вход/Регистрация</h1>
      <PhoneCheckSimple
        phone={login}
        onSubmit={onSubmit}
        isValid={isValid}
        isSubmitting={isSubmitting}
        setValue={setValue}
        methods={methods}
      />
    </>
  );
};
