import React, { useEffect } from 'react';
import './styles.scss';
import { TitleLine } from '@ui';
import { AddCard } from '@features/card/ui';
import { CardModel } from '@features/card/ui/card';
import { useCardsListSelector } from '@eus/react-client/src/cards/selectors';
import { useCardsTdLk } from '../../features/api';

export const CardsPage = () => {
  const cards = useCardsListSelector();

  const { loadCardsList } = useCardsTdLk();

  useEffect(() => {
    loadCardsList({ pagination: { page: 1, perPage: 50 } });
  }, [loadCardsList]);

  return (
    <React.Fragment>
      <TitleLine titleText="Карты" className="titleline_card-page">
        <AddCard />
      </TitleLine>
      {cards && <CardModel data={cards} />}
    </React.Fragment>
  );
};
